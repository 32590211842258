var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Layout", [
    _c("div", { staticClass: "row justify-content-center" }, [
      _c("div", { staticClass: "col-md-8 col-lg-6 col-xl-5" }, [
        _c("div", { staticClass: "card overflow-hidden" }, [
          _c("div", { staticClass: "bg-soft-primary" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-7" }, [
                _c("div", { staticClass: "text-primary p-4" }, [
                  _c("h5", { staticClass: "text-primary" }, [
                    _vm._v("Free Register")
                  ]),
                  _c("p", [_vm._v("Get your free Skote account now.")])
                ])
              ]),
              _c("div", { staticClass: "col-5 align-self-end" }, [
                _c("img", {
                  staticClass: "img-fluid",
                  attrs: {
                    src: require("@/assets/images/profile-img.png"),
                    alt: ""
                  }
                })
              ])
            ])
          ]),
          _c(
            "div",
            { staticClass: "card-body pt-0" },
            [
              _c(
                "div",
                [
                  _c("router-link", { attrs: { tag: "a", to: "/" } }, [
                    _c(
                      "div",
                      { staticClass: "avatar-md profile-user-wid mb-4" },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "avatar-title rounded-circle bg-light"
                          },
                          [
                            _c("img", {
                              staticClass: "rounded-circle",
                              attrs: {
                                src: require("@/assets/images/logo.svg"),
                                alt: "",
                                height: "34"
                              }
                            })
                          ]
                        )
                      ]
                    )
                  ])
                ],
                1
              ),
              _c(
                "b-alert",
                {
                  staticClass: "mt-3",
                  attrs: { variant: "success", dismissible: "" },
                  model: {
                    value: _vm.registerSuccess,
                    callback: function($$v) {
                      _vm.registerSuccess = $$v
                    },
                    expression: "registerSuccess"
                  }
                },
                [_vm._v("Registration successfull.")]
              ),
              _c(
                "b-alert",
                {
                  staticClass: "mt-3",
                  attrs: { variant: "danger", dismissible: "" },
                  model: {
                    value: _vm.isRegisterError,
                    callback: function($$v) {
                      _vm.isRegisterError = $$v
                    },
                    expression: "isRegisterError"
                  }
                },
                [_vm._v(_vm._s(_vm.regError))]
              ),
              _vm.notification.message
                ? _c("div", { class: "alert " + _vm.notification.type }, [
                    _vm._v(_vm._s(_vm.notification.message))
                  ])
                : _vm._e(),
              _c(
                "b-form",
                {
                  staticClass: "p-2",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.tryToRegisterIn($event)
                    }
                  }
                },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "email-group",
                        label: "Username",
                        "label-for": "username"
                      }
                    },
                    [
                      _c("b-form-input", {
                        class: {
                          "is-invalid":
                            _vm.submitted && _vm.$v.user.username.$error
                        },
                        attrs: {
                          id: "username",
                          type: "text",
                          placeholder: "Enter username"
                        },
                        model: {
                          value: _vm.user.username,
                          callback: function($$v) {
                            _vm.$set(_vm.user, "username", $$v)
                          },
                          expression: "user.username"
                        }
                      }),
                      _vm.submitted && !_vm.$v.user.username.required
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v("Username is required.")
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "fullname-group",
                        label: "Email",
                        "label-for": "email"
                      }
                    },
                    [
                      _c("b-form-input", {
                        class: {
                          "is-invalid":
                            _vm.submitted && _vm.$v.user.email.$error
                        },
                        attrs: {
                          id: "email",
                          type: "email",
                          placeholder: "Enter email"
                        },
                        model: {
                          value: _vm.user.email,
                          callback: function($$v) {
                            _vm.$set(_vm.user, "email", $$v)
                          },
                          expression: "user.email"
                        }
                      }),
                      _vm.submitted && _vm.$v.user.email.$error
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            !_vm.$v.user.email.required
                              ? _c("span", [_vm._v("Email is required.")])
                              : _vm._e(),
                            !_vm.$v.user.email.email
                              ? _c("span", [
                                  _vm._v("Please enter valid email.")
                                ])
                              : _vm._e()
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "password-group",
                        label: "Password",
                        "label-for": "password"
                      }
                    },
                    [
                      _c("b-form-input", {
                        class: {
                          "is-invalid":
                            _vm.submitted && _vm.$v.user.password.$error
                        },
                        attrs: {
                          id: "password",
                          type: "password",
                          placeholder: "Enter password"
                        },
                        model: {
                          value: _vm.user.password,
                          callback: function($$v) {
                            _vm.$set(_vm.user, "password", $$v)
                          },
                          expression: "user.password"
                        }
                      }),
                      _vm.submitted && !_vm.$v.user.password.required
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v("Password is required.")
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mt-4" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "btn-block",
                          attrs: { type: "submit", variant: "primary" }
                        },
                        [_vm._v("Register")]
                      )
                    ],
                    1
                  ),
                  _c("div", { staticClass: "mt-4 text-center" }, [
                    _c("h5", { staticClass: "font-size-14 mb-3" }, [
                      _vm._v("Sign in with")
                    ]),
                    _c("ul", { staticClass: "list-inline" }, [
                      _c("li", { staticClass: "list-inline-item" }, [
                        _c(
                          "a",
                          {
                            staticClass:
                              "social-list-item bg-primary text-white border-primary",
                            attrs: { href: "javascript: void(0);" }
                          },
                          [_c("i", { staticClass: "mdi mdi-facebook" })]
                        )
                      ]),
                      _c("li", { staticClass: "list-inline-item" }, [
                        _c(
                          "a",
                          {
                            staticClass:
                              "social-list-item bg-info text-white border-info",
                            attrs: { href: "javascript: void(0);" }
                          },
                          [_c("i", { staticClass: "mdi mdi-twitter" })]
                        )
                      ]),
                      _c("li", { staticClass: "list-inline-item" }, [
                        _c(
                          "a",
                          {
                            staticClass:
                              "social-list-item bg-danger text-white border-danger",
                            attrs: { href: "javascript: void(0);" }
                          },
                          [_c("i", { staticClass: "mdi mdi-google" })]
                        )
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "mt-4 text-center" }, [
                    _c("p", { staticClass: "mb-0" }, [
                      _vm._v(" By registering you agree to the Skote "),
                      _c(
                        "a",
                        {
                          staticClass: "text-primary",
                          attrs: { href: "javascript: void(0);" }
                        },
                        [_vm._v("Terms of Use")]
                      )
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "mt-5 text-center" }, [
          _c(
            "p",
            [
              _vm._v(" Already have an account ? "),
              _c(
                "router-link",
                {
                  staticClass: "font-weight-medium text-primary",
                  attrs: { tag: "a", to: "/login" }
                },
                [_vm._v("Login")]
              )
            ],
            1
          ),
          _c("p", [
            _vm._v(
              " © " + _vm._s(new Date().getFullYear()) + " Skote. Crafted with "
            ),
            _c("i", { staticClass: "mdi mdi-heart text-danger" }),
            _vm._v(" by Themesbrand ")
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }